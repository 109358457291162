import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'


const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate') //changed from 'text-animate'
    const refForm = useRef()



    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000) }
    , [])

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs
            .sendForm(
                'service_fgkcutd',    //service id
                'template_ons4loz', //template id
                refForm.current,
                'EwJxUuqhXFLCb2i_h'  //public id
            )
            .then(
                () => {
                    alert('Message successfully sent!')
                },
                () => {
                    alert('Failed to send the message, try again!')
                }
            )
    }

    return(
        <>
            <div className="container contact-page" >
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters 
                        letterClass={letterClass}
                        strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
                        idx={15}
                        />
                    </h1>
                    <p className='description'>
                        If you are interested in working with me or have any questions, please use the form below to get in touch!
                        Thanks!
                    </p>
                    <div className='contact-form'>
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className="half">
                                    <input type="text" name='name' placeholder='Name' required />
                                </li>
                                <li className="half">
                                    <input type="text" name='email' placeholder='Email' required />
                                </li>
                                <li>
                                    <input placeholder='Subject' type='text' name='subject' required />
                                </li>
                                <li>
                                    <textarea placeholder='Message' name='message' required></textarea>
                                </li>
                                <li>
                                    <input type='submit' className='flat-button' id = "but" value="SEND" />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            
                <div className="info-map">
                    Noah Horbinski,
                    <br />
                    United States,
                    <br />
                    Chattanooga, TN 37421 <br />
                    <span>Noah.horbinski@gmail.com</span>
                    

                </div>
                {/* 35.032851214540536, -85.13865612061991 */}
                {/* 35.040308, -85.149952 */}
                <div className="map-wrap">
                    <MapContainer center={[35.040308, -85.149952]} zoom={12}>
                        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[35.040308, -85.149952]}>
                            <Popup>This is where I currently live</Popup>
                        </Marker>
                    </MapContainer>
            
                </div>
            </div>
            <Loader type="ball-clip-rotate-multiple" />
        </>
    )
}

export default Contact