import './index.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AnimatedLetters from "../../AnimatedLetters";
import { useState, useEffect } from "react";
import { faAngular, faCss3, faGitAlt, faHtml5, faJava, faJsSquare, faReact } from "@fortawesome/free-brands-svg-icons";
import Loader from 'react-loaders';

const About = () => {
    

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return(
        <>

        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass}
                    strArray={['A', 'b', 'o', 'u', 't',' ', 'm', 'e']}
                    idx={15}
                    />
                </h1>
                <p>Hello, I am Noah Horbinski! I am an ambitious full stack developer looking for a role in an established
                    IT company with the opportunity to work with the latest technologies on challenging and diverse projects.
                </p>
                <p>I'm quietly confident, naturally curious, and perpetually working on widening my skillset as a programmer.  </p>
                <p>My favorite hobbies are: backpacking, cycling, reading, and interacting with people. </p>
            </div>

            <div className='stage-cube-cont'>
                <div className="cubespinner">

                    <div className="face1">
                        <FontAwesomeIcon icon={faJava} color="#DD0031" />
                    </div>
                    <div className="face2">
                        <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                    </div>
                    <div className="face3">
                        <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
                    </div>
                    <div className="face4">
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                    </div>
                    <div className="face5">
                        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
                    </div>
                    <div className="face6">
                        <FontAwesomeIcon icon={faGitAlt} color="#ECD428" />
                    </div>

                </div>
            </div>
        </div>
        <Loader type="ball-clip-rotate-multiple" />
        </>
    )

}

export default About;