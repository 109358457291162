import LogoTitle from '../../assets/images/logo-n.png';
import MyPicture from '../../assets/images/me.jpg';
import { Link } from 'react-router-dom';
import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import { useEffect, useState } from 'react';
import Logo from './Logo';
import Loader from 'react-loaders';
import LogoText from './LogoText';


import Lottie from 'lottie-react';
import animationData from '../../assets/images/computerAnimation.json';




const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = [' ', 'N','o', 'a', 'h']
    const jobArray = ['S', 'o', 'f', 't', 'w', 'a', 'r', 'e', ' ', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r', '.']

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 7000)
    }, [])

    
    return (
        <>
        <div className="container home-page">
            <div className="text-zone">
                <h1 className='text-zone-second'>
                <span className={letterClass}>H</span>  
                {/* 12 */}
                <span className={`${letterClass} _12`}>i</span>
                <span className={`${letterClass} _13`}>,</span>   
                <br/> 
                <span className={`${letterClass} _14`}>I</span> 
                <span className={`${letterClass} _15`}>'</span>
                <span className={`${letterClass} _16`}>m</span>  
                {/* <img src={LogoTitle} className='logo-title' alt="developer" /> */}
                <AnimatedLetters letterClass={letterClass}
                strArray={nameArray}
                // 17
                idx={17} />
                <span className={`${letterClass} _22`}> </span>
                <span className={`${letterClass} _23`}>a</span> 
                <span className={`${letterClass} _24`}>n</span>
                <span className={`${letterClass} _25`}>d</span>
                <span className={`${letterClass} _26`}> </span>
                <span className={`${letterClass} _27`}>I</span> 
                <span className={`${letterClass} _28`}>'</span>
                <span className={`${letterClass} _29`}>m</span>
                <span className={`${letterClass} _30`}> </span>
                <span className={`${letterClass} _31`}>a</span> 
                <br/>
                <AnimatedLetters letterClass={letterClass}
                strArray={jobArray}
                //17
                idx={32} />
                </h1>
                <h2 className='text-zone-third'>Software Developer / Full stack developer</h2>
                <Link to='/contact' className='flat-button'>CONTACT ME</Link>
            </div>
            {/* <div className='photo-container'>
                <img className='my-photo' src={MyPicture} alt="me" />
            </div> */}
            <div className="lottie-container">
                <Lottie animationData={animationData}/>
            </div>
        </div>

        <Loader type="ball-clip-rotate-multiple" />

        </>
    )
}

export default Home;