
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/Home/About';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';

function App() {
  return (
    <>
    <Routes>
      <Route path = "/" element = {<Layout />} >
        <Route index element={<Home/>} />
        <Route path="about" index element={<About/>} />
        <Route path="contact" index element={<Contact/>} />
        <Route path="portfolio" index element={<Portfolio/>} />
      </Route>
    </Routes>
    
    </>
  );
}

export default App;
